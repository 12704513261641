import React, { useEffect, useRef, useState } from "react"
import "./App.css"
import { Board, ClientMessage, Result, Row, ServerFullUpdate, ServerMessage } from "./types"

const wsHost = window.location.hostname === "localhost" ? "ws://localhost:8080" : "wss://" + window.location.host
const wsURL = wsHost + `/currentGame/ws`

const icons = ["—", "❤️", "😘", "😻", "🥵", "😈", "🤤"]
const pinCount = icons.length - 1

function App() {
  const socket = useRef<WebSocket | null>(null)
  const retryCount = useRef(0)
  const [socketStatus, setSocketStatus] = useState("uninitialized")
  const [board, setBoard] = useState<Board>()
  const [fullUpdate, setFullUpdate] = useState<ServerFullUpdate>()
  const [gameWon, setGameWon] = useState<null | boolean>(null)

  function getRetryTimeoutMs(retry: number): number {
    const minSleep = 100
    const maxSleep = 30 * 1000

    const sleepNoJitter = minSleep * Math.pow(2, Math.min(20, retry))
    const sleepWithJitter = Math.random() * sleepNoJitter
    const sleep = Math.max(Math.min(sleepWithJitter, maxSleep), minSleep)
    return sleep
  }

  function setupWS() {
    if (socket.current?.readyState === 1) {
      // In case of react debug mode, some invalid interactions will happen that I don't understand!
      // Seems useEffect is called twice on the same component without state being reset. And old websocket tries
      // to reconnect in the new component :/ Normally here we should guard again not retrying if the component is
      // unmounted.
      return
    }

    const ws = new WebSocket(wsURL)
    const debugID = Math.floor(1000 * Math.random())

    ws.onopen = (e) => {
      setSocketStatus("open")
      retryCount.current = 0
    }

    ws.onclose = (e) => {
      setSocketStatus("closed")

      retryCount.current++
      const timeout = getRetryTimeoutMs(retryCount.current)
      console.log(debugID, "will retry in", timeout / 1000, "seconds")
      setTimeout(setupWS, timeout)
    }

    ws.onmessage = (e) => {
      let msg = JSON.parse(e.data) as ServerMessage
      console.log(debugID, "got message", msg)

      if (msg.fullUpdate) {
        setFullUpdate(msg.fullUpdate)
        setBoard(msg.fullUpdate.board)
      } else if (msg.updateBoard) {
        setBoard(msg.updateBoard.board)
      } else if (msg.gameOver) {
        setGameWon(msg.gameOver.won)
      } else {
        console.log("unknown msg!!", msg)
      }
    }

    ws.onerror = (e) => {
      setSocketStatus("error")
      console.log("WS error", e)
    }

    socket.current = ws
    return ws
  }

  useEffect(() => {
    setupWS()

    return () => {
      socket.current?.close()
    }
  }, [])

  function send(c: ClientMessage) {
    console.log("sending", c)
    socket.current!.send(JSON.stringify(c))
  }

  return (
    <>
      {socketStatus !== "open" && <pre>WS status {socketStatus}</pre>}
      {fullUpdate && <Game playerName={fullUpdate.playerName} board={board!} send={send} gameWon={gameWon}></Game>}
    </>
  )
}

interface GameProps {
  playerName: string
  board: Board
  send: (c: ClientMessage) => void
  gameWon: null | boolean
}

function Game({ playerName, board, send, gameWon }: GameProps) {
  const showGoButton = gameWon === null && !board.rows[board.currentRow].pins.includes(null)

  function handleCellClick(row: number, col: number) {
    const current = board.rows[row].pins[col]
    const newPin = ((current ?? 0) % pinCount) + 1

    send({
      setPin: {
        row: row,
        column: col,
        pin: newPin,
      },
    })
  }

  function handleSubmit() {
    send({
      submitRow: {},
    })
  }

  function renderResult(result: Result) {
    return (
      <div className="result">
        {Array(result.full)
          .fill("⚫")
          .map((text, idx) => (
            <span key={idx}>️{text}</span>
          ))}
        {Array(result.partial)
          .fill("⚪️️")
          .map((text, idx) => (
            <span key={idx}>{text}</span>
          ))}
      </div>
    )
  }

  function renderRow(row: Row, rowIdx: number) {
    if (gameWon === true && rowIdx > board.currentRow) {
      return
    }
    return (
      <div className="row" key={rowIdx}>
        {row.pins.map((pin, colIdx) => (
          <div className="cell" key={colIdx} onClick={() => handleCellClick(rowIdx, colIdx)}>
            {icons[pin ?? 0]}
          </div>
        ))}
        {row.result && renderResult(row.result)}
        {rowIdx === board.currentRow && showGoButton && <button onClick={() => handleSubmit()}>Go</button>}
      </div>
    )
  }

  return (
    <div className="game">
      <h3>
        Hello {playerName}!<br />
        Solve the mystery love code
      </h3>
      <div className="board">{board.rows.map(renderRow)}</div>
      {gameWon === true && <h1>WOW 🎉👩‍❤️‍💋‍👨</h1>}
      {gameWon === false && <h1>🥺Maybe next time</h1>}
    </div>
  )
}

export default App
